import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { db } from "../../../../../firebase/firebase.config";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loading from "../../../../../components/Loading/Loading";

const formateDate = (d) => {
	let day = d.getDate().toString().padStart(2, "0");
	let month = (d.getMonth() + 1).toString().padStart(2, "0");
	let year = d.getFullYear();
	return `${year}-${month}-${day}`;
};

const formateDateYYYYMMDD = (dateString) => {
	const year = dateString.slice(0, 4);
	const month = dateString.slice(4, 6);
	const day = dateString.slice(6, 8);
	return `${year}-${month}-${day}`;
};

function combineDate(d) {
	return d.getFullYear().toString() + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0");
}
const dateRangeGenerator = function* (start, end, step = 1) {
	let d = start;
	while (d <= end) {
		yield combineDate(d);
		d.setDate(d.getDate() + step);
	}
};

const Violations = ({ selectedClient, selectedLocation }) => {
	const [violationsReport, setViolationsReport] = useState([]);

	const [dateRange, setDateRange] = useState([new Date().getTime() - 7 * 24 * 60 * 60 * 1000, new Date()]);
	const [startDate, endDate] = dateRange;

	const [loading, setLoading] = useState(true);

	async function fetchViolations() {
		const start = new Date(startDate);
		const startingDateString = combineDate(start);
		const end = new Date(endDate);
		const endingDateString = combineDate(end);

		const dateRange = [...dateRangeGenerator(start, end)];
		const datesInDb = new Set();

		let violations = [];

		const q = query(
			collection(db, "Violations"),
			where("DateStamp", ">=", startingDateString),
			where("DateStamp", "<=", endingDateString),
			where("ClientID", "==", selectedClient.id),
			where("LocationID", "==", selectedLocation.id)
		);
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			const data = doc.data();
			datesInDb.add(data["DateStamp"]);
			violations.push({
				Date: formateDateYYYYMMDD(data["DateStamp"]),
				"Authorized Count Exceeded": data?.["Authorized Count Exceeded"] ? data["Authorized Count Exceeded"] : 0,
				"Unauthorized Entry": data?.["Unauthorized Entry"] ? data["Unauthorized Entry"] : 0,
				"No Movement Day": data?.["No Movement day"] ? data["No Movement day"] : data?.["No Movement Day"] ? data["No Movement Day"] : 0,
				"Night Movement": data?.["Night Movement"] ? data["Night Movement"] : 0,
			});
		});

		// adding dates that were not in db
		dateRange.forEach((ele) => {
			if (!datesInDb.has(ele)) {
				violations.push({
					Date: formateDateYYYYMMDD(ele),
					"Authorized Count Exceeded": 0,
					"Unauthorized Entry": 0,
					"No Movement Day": 0,
					"Night Movement": 0,
				});
			}
		});
		violations.sort((a, b) => a["Date"] < b["Date"]);

		setViolationsReport(violations);
		setLoading(false);
	}

	useEffect(() => {
		if (selectedClient && selectedLocation && startDate && endDate) {
			setLoading(true);
			fetchViolations();
		}
	}, [startDate, endDate, selectedClient, selectedLocation]);

	return (
		<div className="ml-6">
			<h1 className="text-sky-900 text-2xl font-bold mr-3">Violations</h1>
			<div className="flex flex-col gap-3 my-4">
				<div className="flex flex-row justify-between items-center">
					<div className="flex flex-row gap-8">
						<h1 className="text-black text-lg">Date range:</h1>
						<DatePicker
							className="bg-gray-100 text-black w-fit px-5"
							selectsRange={true}
							startDate={startDate}
							endDate={endDate}
							onChange={(update) => {
								setDateRange(update);
							}}
							isClearable={true}
							showIcon
							placeholderText="Select Date range"
							renderInput
						/>
					</div>
					{!loading && violationsReport.length && (
						<CSVLink
							data={violationsReport}
							filename={"Violations Report.csv"}
							className="mr-6 bg-green-500 hover:bg-green-900 hover:border-green-300 px-2 py-1 active:border-2 active:border-green-900 w-36 text-center text-white font-semibold h-[2.5rem]   border-2 border-green-800"
						>
							Download
						</CSVLink>
					)}
				</div>
				{startDate && endDate ? (
					loading ? (
						<Loading />
					) : (
						<div className="flex flex-col gap-7 mr-6 overflow-x-auto overflow-y-auto max-h-[70vh]">
							<table className="my-5 text-center self-center w-full border-t-[3px] border-t-black border-b-2 border-b-black">
								<thead className="sticky top-0 bg-gray-100">
									<tr className="border-b text-black">
										<th className="p-3 max-w-[200] xl:text-base text-sm">Date</th>
										<th className="p-3 max-w-[200] xl:text-base text-sm">Authorized Count Exceeded</th>
										<th className="p-3 max-w-[200] xl:text-base text-sm">No Movement Day</th>
										<th className="p-3 max-w-[200] xl:text-base text-sm">Night Movement</th>
										<th className="p-3 max-w-[200] xl:text-base text-sm">Unauthorized Entry</th>
									</tr>
								</thead>
								<tbody className="bg-sky-100">
									{violationsReport.map((ele) => (
										<tr key={ele["Date"]} className="border-b text-black">
											<td className="p-3 max-w-[80] xl:text-base text-sm">{ele["Date"]}</td>
											<td className="p-3 max-w-[80] xl:text-base text-sm">{ele["Authorized Count Exceeded"]}</td>
											<td className="p-3 max-w-[80] xl:text-base text-sm">{ele["No Movement Day"]}</td>
											<td className="p-3 max-w-[80] xl:text-base text-sm">{ele["Night Movement"]}</td>
											<td className="p-3 max-w-[80] xl:text-base text-sm">{ele["Unauthorized Entry"]}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)
				) : (
					<div className="flex flex-row justify-around my-6">
						<div className="alert alert-info px-20 w-fit">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6">
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
								></path>
							</svg>
							<span>
								Select {!startDate && "Starting date and"} {!endDate && "Ending date"}
							</span>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Violations;
