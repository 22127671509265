import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { db } from "../../../../../firebase/firebase.config";
import { collection, doc, getDoc, onSnapshot, orderBy, query, where } from "firebase/firestore";
import Loading from "../../../../../components/Loading/Loading";
import { CSVLink } from "react-csv";
import Select from "react-select";
import isTimestampDifferenceWithinThreshold from "../../../../../Utility/isTimestampDifferenceWithinThreshold";

const formateDate = (d) => {
	let day = d.getDate().toString().padStart(2, "0");
	let month = (d.getMonth() + 1).toString().padStart(2, "0");
	let year = d.getFullYear();
	return `${year}-${month}-${day}`;
};

const formateTime = (timestamp) => {
	const time = timestamp.split("-")[1];
	const hh = time.slice(0, 2);
	const mm = time.slice(2, 4);
	const ss = time.slice(4, 6);
	return `${hh}:${mm}:${ss}`;
};

const combineFormattedDate = (d) => d.split("-").join("");

// custom styling for react-select
const selectStyles = {
	// menuList: (styles) => {
	// 	// console.log("menuList:", styles);
	// 	return {
	// 		...styles,
	// 		maxHeight: 136,
	// 	};
	// },
	container: (provided) => ({
		...provided,
		// width: 350,
		minWidth: 300,
		maxWidth: 646,
	}),
};

const EventDetails = ({ selectedClient, selectedLocation }) => {
	const [detectionOptions, setDetectionOptions] = useState([]); // for react-select component, each object is of type {label:<string>,value:<string>} , provides list of options to choose from
	const [selectedDetectionOptions, setSelectedDetectionOptions] = useState([]); // for react-select component, each object is of type {label:<string>,value:<string>} , depicts currently selected detections
	const [date, setDate] = useState(formateDate(new Date()));

	const [loading, setLoading] = useState(true);

	const [eventsData, setEventData] = useState([]);
	const [reportType, setReportType] = useState(false); // false means all , true means unique
	const [eventsDetailReport, setEventsDetailReport] = useState([]);

	const fetchCameraName = async (deviceID, cameraID) => {
		const docRef = doc(db, `device-data-collection/${deviceID}/Cameras`, cameraID);
		const docSnap = await getDoc(docRef);
		if (!docSnap.exists()) return "Null";
		return docSnap.data().CameraName;
	};

	const setReportAccordingToType = () => {
		if (!reportType) {
			setEventsDetailReport(
				eventsData.map((event) => ({
					Time: formateTime(event.Timestamp),
					Zone: event.Zone,
					"Camera Name": event["Camera Name"],
					Detection: event.Class_label,
					Action: event.Direction,
				}))
			);
			setLoading(false);
			return;
		}

		let report = [];
		let previousEvent = {};
		let key;
		for (let i = 0; i < eventsData.length; i++) {
			const direction = eventsData[i]?.Direction;
			const timeStamp = eventsData[i]?.Timestamp;
			const classLabel = eventsData[i]?.Class_label;
			const locationID = eventsData[i]?.["Location-ID"];
			const deviceID = eventsData[i]?.["Device-ID"];
			const zoneID = eventsData[i]?.["Zone-ID"];
			key = `${locationID}_${zoneID}_${direction}_${classLabel}`;

			let considerEvent = true;

			if (Object.hasOwn(previousEvent, key)) {
				if (deviceID !== previousEvent?.[key]?.deviceID && zoneID === previousEvent?.[key]?.zoneID) {
					// Check if the class_label and direction are the same
					if (classLabel === previousEvent?.[key]?.classLabel && direction === previousEvent[key]?.direction)
						if (!isTimestampDifferenceWithinThreshold(timeStamp, previousEvent?.[key]?.timestamp)) {
							considerEvent = false; // Skip counting the event if the above conditions are true
						}
				}
			} else {
				previousEvent[key] = {};
			}

			if (considerEvent) {
				report.push({
					Time: formateTime(eventsData[i].Timestamp),
					Zone: eventsData[i].Zone,
					"Camera Name": eventsData[i]["Camera Name"],
					Detection: classLabel,
					Action: direction,
				});

				previousEvent[key].deviceID = deviceID;
				previousEvent[key].zoneID = zoneID ? zoneID : null;
				previousEvent[key].classLabel = classLabel ? classLabel : null;
				previousEvent[key].direction = direction ? direction : null;
				previousEvent[key].timestamp = timeStamp;
			}
		}
		setEventsDetailReport(report);
		setLoading(false);
		return;
	};

	async function fetchDetections() {
		const docRef = doc(db, `clients-data-collection/${selectedClient.id}/Location/${selectedLocation.id}/Detections`, "detection-list");
		const docSnap = await getDoc(docRef);
		let detectionList = docSnap.data()?.Vehicles;
		detectionList = detectionList.filter((detection) => detection !== "Numberplate").sort();
		let options = detectionList.map((detection) => ({ value: detection, label: detection }));
		setDetectionOptions(options);
		setSelectedDetectionOptions(options);
	}

	const fetchEvents = async () => {
		const detectionList = selectedDetectionOptions.map((detectionObj) => detectionObj.value);
		const q = query(
			collection(db, `${selectedClient.id}-detection-events-${combineFormattedDate(date)}`),
			where("Location-ID", "==", selectedLocation.id),
			where("Class_label", "in", detectionList)
		);

		const unsubscribe = onSnapshot(q, async (querySnapshot) => {
			if (querySnapshot.empty) {
				setLoading(false);
				setEventsDetailReport([]);
				return;
			}

			const promises = querySnapshot.docs.map(async (doc) => {
				const data = doc.data();
				const cameraName = await fetchCameraName(data["Device-ID"], data["Camera-ID"]);
				return { "Camera Name": cameraName, ...data };
			});

			let events = await Promise.all(promises);
			events = events.sort((a, b) => {
				if (a?.Timestamp < b?.Timestamp) return 1;
				if (b?.Timestamp < a?.Timestamp) return -1;
				return 0;
			});
			setEventData(events);
		});

		return () => {
			unsubscribe();
		};
	};

	useEffect(() => {
		if (selectedClient?.id && selectedLocation?.id) fetchDetections();
	}, [selectedClient?.id, selectedLocation?.id]);

	useEffect(() => {
		if (selectedClient?.id && selectedLocation?.id && date && selectedDetectionOptions.length) {
			setLoading(true);
			fetchEvents();
		}
	}, [selectedClient?.id, selectedLocation?.id, date, selectedDetectionOptions, detectionOptions]);

	useEffect(() => {
		if (eventsData.length) setReportAccordingToType();
	}, [reportType, eventsData]);

	const handleDetectionSelect = (selectedOptions) => {
		setSelectedDetectionOptions(selectedOptions);
	};

	return (
		<div className="ml-6 my-4">
			<h1 className="text-sky-900 text-2xl font-bold mr-3">Events Details</h1>
			<div className="flex flex-col gap-3 my-5">
				<div className="flex flex-row justify-between items-center">
					<div className="flex flex-row gap-6">
						{/* Date */}
						<div className="flex flex-row items-center gap-4">
							<h1 className="text-black text-lg">Date:</h1>
							<input
								className="bg-gray-100 rounded text-black w-32"
								id="datepicker"
								type="date"
								placeholder="Pick Date"
								value={date}
								onChange={(e) => setDate(e.target.value)}
								max={formateDate(new Date())}
							/>
						</div>
						<div className="flex flex-row items-center self-center gap-3 bg-slate-200 p-1 h-fit rounded">
							<h1 className="text-black text-lg">All</h1>
							<input type="checkbox" className="toggle toggle-info" value={reportType} onChange={(e) => setReportType(e.target.checked)} />
							<h1 className="text-black text-lg">Unique</h1>
						</div>
						<div className="flex flex-row items-center gap-4">
							<h1 className="text-black text-lg">Detections:</h1>
							<Select
								className="text-black"
								options={detectionOptions}
								placeholder={"Select Detections"}
								value={selectedDetectionOptions}
								onChange={handleDetectionSelect}
								styles={selectStyles}
								isMulti
							/>
						</div>
					</div>

					{!loading && eventsDetailReport.length && (
						<CSVLink
							data={eventsDetailReport}
							filename={
								reportType
									? `${new Date(date).toLocaleDateString("en-us", {
											year: "numeric",
											month: "short",
											day: "numeric",
									  })} Unique Events Detail Report.csv`
									: `${new Date(date).toLocaleDateString("en-us", {
											year: "numeric",
											month: "short",
											day: "numeric",
									  })} Events Detail Report.csv`
							}
							className="mr-6 bg-green-500 hover:bg-green-900 hover:border-green-300 px-2 py-1 active:border-2 active:border-green-900 w-36 text-center text-white font-semibold h-[2.5rem]   border-2 border-green-800"
						>
							Download
						</CSVLink>
					)}
				</div>
				{loading ? (
					<Loading />
				) : eventsDetailReport.length ? (
					<div className="flex flex-col gap-7 mr-6 overflow-x-auto overflow-y-auto max-h-[70vh]">
						<table className="text-center self-center w-full border-t-[3px] border-t-black border-b-2 border-b-black">
							<thead className="sticky top-0 bg-gray-100">
								<tr className="border-b text-black">
									<th className="p-1 max-w-[10] xl:text-base text-sm">S. No.</th>
									<th className="p-3 max-w-[100] xl:text-base text-sm">Time (hh:mm:ss)</th>
									<th className="p-3 max-w-[100] xl:text-base text-sm">Zone</th>
									<th className="p-3 max-w-[100] xl:text-base text-sm">Camera Name</th>
									<th className="p-3 max-w-[100] xl:text-base text-sm">Detection</th>
									<th className="p-3 max-w-[100] xl:text-base text-sm">Action</th>
								</tr>
							</thead>
							<tbody className="bg-sky-100">
								{eventsDetailReport.map((ele, i) => (
									<tr key={`${i}_${ele["Time"]}`} className="border-b text-black">
										<td className="p-1 max-w-[10] xl:text-base text-sm">{i + 1}</td>
										<td className="p-3 max-w-[100] xl:text-base text-sm">{ele["Time"]}</td>
										<td className="p-3 max-w-[100] xl:text-base text-sm">{ele["Zone"]}</td>
										<td className="p-3 max-w-[100] xl:text-base text-sm">{ele["Camera Name"]}</td>
										<td className="p-3 max-w-[100] xl:text-base text-sm">{ele["Detection"]}</td>
										<td className="p-3 max-w-[100] xl:text-base text-sm">{ele["Action"]}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				) : (
					<div className="flex flex-row justify-around my-10">
						<div className="alert alert-info px-20 w-fit">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6">
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
								></path>
							</svg>
							<span>No Data for {date} is available yet !</span>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default EventDetails;
