import { collection, doc, getDoc, onSnapshot, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../../../../firebase/firebase.config";
import { CSVLink } from "react-csv";
import Loading from "../../../../../../components/Loading/Loading";
import ResolutionsModal from "./Modal/ResolutionsModal";
import { MdReadMore } from "react-icons/md";

const formateDate = (d) => {
	let day = d.getDate().toString().padStart(2, "0");
	let month = (d.getMonth() + 1).toString().padStart(2, "0");
	let year = d.getFullYear();
	return `${year}-${month}-${day}`;
};

const formateTime = (timestamp) => {
	const time = timestamp.split("-")[1];
	const hh = time.slice(0, 2);
	const mm = time.slice(2, 4);
	const ss = time.slice(4, 6);
	return `${hh}:${mm}:${ss}`;
};

const combineFormattedDate = (d) => d.split("-").join("");

const PriorityEventsDetails = ({ selectedClient, selectedLocation }) => {
	const [priorityEvents, setPriorityEvents] = useState([]);
	const [report, setReport] = useState([]);

	const [date, setDate] = useState(formateDate(new Date()));

	const [modal, setModal] = useState(false);
	const [dataToDisplay, setDataToDisplay] = useState(null);

	const [loading, setLoading] = useState(true);

	const fetchCameraName = async (deviceID, cameraID) => {
		const docRef = doc(db, `device-data-collection/${deviceID}/Cameras`, cameraID);
		const docSnap = await getDoc(docRef);
		if (!docSnap.exists()) return "Null";
		return docSnap.data().CameraName;
	};

	const generateReport = (events) => {
		let report = events.map((event) => {
			let reportData = { ...event };
			event["Resolutions"].forEach((resolution, i) => {
				reportData[`Validation ${i + 1}`] = resolution["Validated By"];
				reportData[`Remarks ${i + 1}`] = resolution["Remarks"];
			});

			delete reportData["Resolutions"];
			return reportData;
		});
		setReport(report);
	};

	/**
	 * Retrieves an array of objects containing validation information.
	 *
	 * @param {string} eventID - The unique identifier for the validation.
	 * @returns {Promise<Array<{ "Validated By": string | undefined, Remarks: string | undefined }>>} An array of objects with validation details.
	 * @throws {Error} Throws an error if the specified ID is not found or if there's an issue retrieving the data.
	 */
	const fetchResolutionData = async (eventID) => {
		const docRef = doc(db, "ResolvedIssuesCollection", eventID);
		const docSnap = await getDoc(docRef);

		if (!docSnap.exists()) {
			throw new Error(`No Priority Event with event ID '${eventID}' exists.`);
		}

		const data = docSnap.data();
		const resolutionData = data["resolvedIssues"].map((ele) => ({
			"Validated By": ele?.userName,
			Remarks: ele?.promptInput,
		}));

		return resolutionData;
	};

	const fetchPriorityEvents = async () => {
		const q = query(
			collection(db, `${selectedClient.id}-detection-events-${combineFormattedDate(date)}`),
			where("Location-ID", "==", selectedLocation.id),
			where("Priority", "==", "Priority")
		);

		const unsubscribe = onSnapshot(q, async (querySnapshot) => {
			if (querySnapshot.empty) {
				setLoading(false);
				setPriorityEvents([]);
				return;
			}

			// let index = 0;
			const promises = querySnapshot.docs.map(async (doc) => {
				const data = doc.data();
				const cameraName = await fetchCameraName(data["Device-ID"], data["Camera-ID"]);
				let res = {
					// "S.No.": ++index,
					Time: formateTime(data.Timestamp),
					Zone: data.Zone,
					"Camera Name": cameraName,
					Detection: data.Class_label,
					Action: data.Direction,
					Status: data.Status,
					Violation: data.Incident,
				};
				if (data.Status !== "Pending") res["Resolutions"] = await fetchResolutionData(doc.id);
				else res["Resolutions"] = [];
				return res;
			});

			let events = await Promise.all(promises);
			events = events.sort((a, b) => a.Timestamp < b.Timestamp);
			setPriorityEvents(events);
			generateReport(events);
			setLoading(false);
		});

		return () => {
			unsubscribe();
		};
	};

	useEffect(() => {
		if (selectedClient?.id && selectedLocation?.id && date) {
			setLoading(true);
			fetchPriorityEvents();
		}
	}, [selectedClient?.id, selectedLocation?.id, date]);

	return (
		<div className="mx-6 my-4">
			<h1 className="text-sky-900 text-2xl font-bold mr-3">Priority Events Details</h1>
			<div className="flex flex-col gap-3 my-5">
				<div className="flex flex-row justify-between items-center">
					{/* Date */}
					<div className="flex flex-row items-center gap-4">
						<h1 className="text-black text-lg">Date:</h1>
						<input
							className="bg-gray-100 rounded text-black w-32"
							id="datepicker"
							type="date"
							placeholder="Pick Date"
							value={date}
							onChange={(e) => setDate(e.target.value)}
							max={formateDate(new Date())}
						/>
					</div>

					{!loading && priorityEvents.length && (
						<CSVLink
							data={report}
							filename={`${new Date(date).toLocaleDateString("en-us", {
								year: "numeric",
								month: "short",
								day: "numeric",
							})} Priority Events Details.csv`}
							className="mr-6 bg-green-500 hover:bg-green-900 hover:border-green-300 px-2 py-1 active:border-2 active:border-green-900 w-36 text-center text-white font-semibold h-[2.5rem]   border-2 border-green-800"
						>
							Download
						</CSVLink>
					)}
				</div>
				{loading ? (
					<Loading />
				) : priorityEvents.length ? (
					<div className="flex flex-col overflow-x-visible overflow-y-auto max-h-[70vh]">
						<table className="text-center self-center w-full border-t-[3px] border-t-black border-b-2 border-b-black">
							<thead className="sticky top-0 bg-gray-100">
								<tr className="border-b text-black">
									<th className="p-1 max-w-[10] xl:text-base text-sm">S. No.</th>
									<th className="p-3 max-w-[50] xl:text-base text-sm">Time (hh:mm:ss)</th>
									<th className="p-3 max-w-[50] xl:text-base text-sm">Zone</th>
									<th className="p-3 max-w-[50] xl:text-base text-sm">Camera Name</th>
									<th className="p-3 max-w-[50] xl:text-base text-sm">Detection</th>
									<th className="p-3 max-w-[50] xl:text-base text-sm">Action</th>
									<th className="p-3 max-w-[50] xl:text-base text-sm">Status</th>
									<th className="p-3 max-w-[50] xl:text-base text-sm">Violation</th>
									<th className="p-3 max-w-[50] xl:text-base text-sm">
										<div
											className="tooltip tooltip-left tooltip-info"
											data-tip="Click on Non Pending Event resolutions to see all remarks and validations by users"
										>
											Resolutions
										</div>
									</th>
								</tr>
							</thead>
							<tbody className="bg-sky-100">
								{priorityEvents.map((event, i) => (
									<tr key={`${i}_${event["Time"]}`} className="border-b text-black">
										<td className="p-1 max-w-[10] xl:text-base text-sm">{i + 1}</td>
										<td className="p-3 max-w-[50] xl:text-base text-sm">{event["Time"]}</td>
										<td className="p-3 max-w-[50] xl:text-base text-sm">{event["Zone"]}</td>
										<td className="p-3 max-w-[50] xl:text-base text-sm">{event["Camera Name"]}</td>
										<td className="p-3 max-w-[50] xl:text-base text-sm">{event["Detection"]}</td>
										<td className="p-3 max-w-[50] xl:text-base text-sm">{event["Action"]}</td>
										<td className="p-3 max-w-[50] xl:text-base text-sm">{event["Status"]}</td>
										<td className="p-3 max-w-[50] xl:text-base text-sm">{event["Violation"]}</td>
										<td className="pr-1 max-w-[50] xl:text-base text-sm">
											{event["Resolutions"].length ? (
												<label
													className="btn btn-ghost !normal-case !w-full !h-full !text-left !font-normal !rounded-none !shadow-md"
													onClick={() => {
														setDataToDisplay(event["Resolutions"]);
														setModal(true);
													}}
													htmlFor="modal"
												>
													<div className="w-full flex flex-row justify-between">
														<div className="flex flex-col gap-[1px]">
															<p className="py-1 pl-2 pr-1 xl:text-base text-sm">
																<span className="font-semibold">Validated By:</span> {event["Resolutions"].at(0)["Validated By"]}
															</p>
															<p className="py-1 pl-2 pr-1 xl:text-base text-sm">
																<span className="font-semibold">Remarks:</span> {event["Resolutions"].at(0)["Remarks"]}
															</p>
														</div>
														<div className="self-center">{event["Resolutions"].length > 1 && <MdReadMore className="text-3xl" />}</div>
													</div>
												</label>
											) : (
												<span>No Resolutions</span>
											)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				) : (
					<div className="flex flex-row justify-around my-10">
						<div className="alert alert-info px-20 w-fit">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6">
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
								></path>
							</svg>
							<span>No Priority Events for {date} is available !</span>
						</div>
					</div>
				)}
			</div>

			{modal && <ResolutionsModal resolutionsData={dataToDisplay} setModal={setModal} />}
		</div>
	);
};

export default PriorityEventsDetails;
