import React from 'react';
import logo from '../images/logo.png';

const AlMonkLogo = () => {
    return (
        <div>
            <div className='w-[200px] bg-[#ff847c] flex justify-evenly items-center'>
                <a href="https://www.aimlmonks.com/" target="_blank" rel="noreferrer">
                    <img src={logo} alt="logo" />
                </a>
                <p className='text-white text-xl'>AI/ML Monks</p>
            </div>
        </div>
    );
};

export default AlMonkLogo;