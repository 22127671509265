import React, { useEffect, useState } from "react";
import { db } from "../../../../../firebase/firebase.config";
import { doc, getDoc } from "firebase/firestore";
import { CSVLink } from "react-csv";
import Loading from "../../../../../components/Loading/Loading";
import { DataContext } from "../../../../../context/DataProvider/DataProvider";
import { useContext } from "react";

const formateDate = (d) => {
	let day = d.getDate().toString().padStart(2, "0");
	let month = (d.getMonth() + 1).toString().padStart(2, "0");
	let year = d.getFullYear();
	return `${year}-${month}-${day}`;
};

function combineDate(date) {
	const d = new Date(date);
	return d.getFullYear().toString() + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0");
}

const Daily = ({ selectedClient, selectedLocation }) => {
	const { devices, dataLoading } = useContext(DataContext);

	const [dailyReport, setDailyReport] = useState([]);

	const [date, setDate] = useState(formateDate(new Date()));

	const [loading, setLoading] = useState(true);

	async function fetchDailyReport() {
		const docRef = doc(db, "actualCountCollection", `${selectedClient.id}-${combineDate(new Date(date))}`);
		const docSnap = await getDoc(docRef);

		let report = [];

		let data = docSnap.data();
		if (!data) {
			setDailyReport([]);
			setLoading(false);
			return;
		}

		const zoneDataArray = devices.filter((ele) => ele.Location_ID === selectedLocation.id && ele.ClientID === selectedClient.id);
		const uniqueZoneDataArray = [...new Map(zoneDataArray.map((item) => [item["ZoneID"], item])).values()];

		uniqueZoneDataArray.forEach((ele) => {
			let zoneEntryExitCnt = { Zone: ele.Zone, Entry: 0, Exit: 0 };

			const entryKey = `${ele.Location_ID}_${ele.ZoneID}_Entry`;
			if (Object.hasOwn(data, entryKey)) {
				for (const [key, value] of Object.entries(data[entryKey])) {
					if (key !== "zoneID" && key !== "classLabel" && key !== "deviceID" && key !== "direction" && key !== "timestamp")
						if (value.toString() !== "NaN") zoneEntryExitCnt["Entry"] += parseInt(value);
				}
			}

			const exitKey = `${ele.Location_ID}_${ele.ZoneID}_Exit`;
			if (Object.hasOwn(data, exitKey)) {
				for (const [key, value] of Object.entries(data[exitKey])) {
					if (key !== "zoneID" && key !== "classLabel" && key !== "deviceID" && key !== "direction" && key !== "timestamp")
						if (value.toString() !== "NaN") zoneEntryExitCnt["Exit"] += parseInt(value);
				}
			}

			report.push(zoneEntryExitCnt);
		});

		if (!report.length) {
			setDailyReport([]);
			setLoading(false);
			return;
		}

		setDailyReport(report);
		setLoading(false);
	}

	// for daily report
	useEffect(() => {
		if (selectedClient?.id && selectedLocation?.id && date && !dataLoading && devices.length) {
			setLoading(true);
			fetchDailyReport();
		}
	}, [selectedClient?.id, selectedLocation?.id, dataLoading, devices, date]);

	return (
		<div className="ml-6 my-4">
			<h1 className="text-sky-900 text-2xl font-bold mr-3">Daily</h1>
			<div className="flex flex-col gap-3 my-5">
				<div className="flex flex-row justify-between items-center">
					{/* Date */}
					<div className="flex flex-row gap-8">
						<h1 className="text-black text-lg">Date:</h1>
						<input
							className="bg-gray-100 rounded text-black w-32"
							id="datepicker"
							type="date"
							placeholder="Pick Date"
							value={date}
							onChange={(e) => setDate(e.target.value)}
							max={formateDate(new Date())}
						/>
					</div>
					{!loading && dailyReport.length && (
						<CSVLink
							data={dailyReport}
							filename={`${new Date(date).toLocaleDateString("en-us", {
								year: "numeric",
								month: "short",
								day: "numeric",
							})} Daily Report.csv`}
							className="mr-14 bg-green-500 hover:bg-green-900 hover:border-green-300 px-2 py-1 active:border-2 active:border-green-900 w-36 text-center text-white font-semibold h-[2.5rem]   border-2 border-green-800"
						>
							Download
						</CSVLink>
					)}
				</div>
				{loading ? (
					<Loading />
				) : dailyReport.length ? (
					<div className="flex flex-col gap-7 mr-14 overflow-x-auto overflow-y-auto max-h-[70vh]">
						<table className="my-5 text-center self-center w-1/2 border-t-[3px] border-t-black border-b-2 border-b-black">
							<thead className="sticky top-0 bg-gray-100">
								<tr className="border-b text-black">
									<th className="p-3  max-w-[200] xl:text-base text-sm">Zones</th>
									<th className="p-3  max-w-[200] xl:text-base text-sm">Entry</th>
									<th className="p-3  max-w-[200] xl:text-base text-sm">Exit</th>
								</tr>
							</thead>
							<tbody className="bg-sky-100">
								{dailyReport.map((ele) => (
									<tr key={ele.Zone} className="border-b text-black">
										<td className="p-3 max-w-[80] xl:text-base text-sm">{ele.Zone}</td>
										<td className="p-3 max-w-[80] xl:text-base text-sm">{ele.Entry}</td>
										<td className="p-3 max-w-[80] xl:text-base text-sm">{ele.Exit}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				) : (
					<div className="flex flex-row justify-around my-6">
						<div className="alert alert-info px-20 w-fit">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current shrink-0 w-6 h-6">
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
								></path>
							</svg>
							<span>No Data for {date} is available yet !</span>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Daily;
