import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { db } from "../../../../../../firebase/firebase.config";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import Loading from "../../../../../../components/Loading/Loading";
import Select from "react-select";
import WeekDaysPicker from "./Sections/WeeklyDaysPicker/WeekDaysPicker";
import MonthlyDaysPicker from "./Sections/MonthlyDaysPicker/MonthlyDaysPicker";
import NonRepetitiveDates from "./Sections/NonRepetitiveDays/NonRepetitiveDates";
import PurposeList from "./Sections/PurposeList/PurposeList";
import RegisteredOrganizationMain from "./Sections/RegisteredOrganizations/RegisteredOrganizationMain";
import TimePickerMain from "./Sections/TimePickerSection/TimePickerSectionMain";
import DetectionEventTypes from "./Sections/DetectionEventTypes/DetectionEventTypes";
import toast from "react-hot-toast";

// custom styling for react-select
const selectStyles = {
  // menuList: (styles) => {
  // 	// console.log("menuList:", styles);
  // 	return {
  // 		...styles,
  // 		maxHeight: 136,
  // 	};
  // },
  container: (provided) => ({
    ...provided,
    width: "410px",
    // height: "55px",
    minHeight: "55px",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#2E3192",
    borderRadius: "8px",
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: "#F2F5F7", // Set the background color for the control (input)
    minHeight: "55px",
    borderRadius: "8px",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#F2F5F7", // Set the background color for the dropdown menu
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "block", // Ensure the indicator separator (dropdown icon) is always visible
    backgroundColor: "#2E3192",
  }),
};

const SecurityAgentInitiationAlertMain = ({ selectedClientID, selectedLocationID, devices }) => {
  const [zoneOptions, setZoneOptions] = useState([]);
  const [selectedZoneOption, setSelectedZoneOption] = useState({ value: "", label: "" });

  const [detectionOptions, setDetectionOptions] = useState([]); // options for react-select
  const [selectedDetectionOptions, setSelectedDetectionOptions] = useState([]); // to control react-select

  const [eventTypesOptions, setEventTypesOptions] = useState([]);
  const [selectedEventTypesOptions, setSelectedEventTypesOptions] = useState([]);

  // for handling time
  const [startingTime, setStartingTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [endingTime, setEndingTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [purposeList, setPurposeList] = useState([]);
  const [registeredOrganizationList, setRegisteredOrganizationList] = useState([]);

  const [datesConfig, setDatesConfig] = useState({ /* MonthlyRepetitiveDays: [], */ ExclusiveDates: [], WeeklyRepetitiveDays: [] });

  const [alertProfile, setAlertProfile] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isEvent, setIsEvent] = useState(false);
  const [isEntry, setIsEntry] = useState(false);

  //fetch detection list for selected client location
  const fetchDetections = async () => {
    const detectionCollectionRef = doc(db, `clients-data-collection/${selectedClientID}/Location/${selectedLocationID}/Detections`, "detection-list");
    const docSnap = await getDoc(detectionCollectionRef);
    let detections = docSnap.data()?.Vehicles;
    detections = detections.filter((detection) => detection !== "Numberplate").sort();
    setDetectionOptions([...detections.map((ele) => ({ value: ele, label: ele }))]);
  };

  const fetchEventTypes = async () => {
    const detectionCollectionRef = doc(db, `clients-data-collection/${selectedClientID}/Location/${selectedLocationID}/Directions`, "direction-list");
    const docSnap = await getDoc(detectionCollectionRef);
    let directions = docSnap.data()?.["direction-list"].sort();
    setEventTypesOptions([...directions.map((ele) => ({ value: ele, label: ele }))]);
  };

  async function updateDB() {
    const alertCollectionRef = doc(db, "SecurityAgentInitiationAlert", alertProfile);
    const detections = selectedDetectionOptions.map((detection) => detection.value);
    const events = selectedEventTypesOptions.map((event) => event.value);
    const startTime = startingTime.hours.toString().padStart(2, "0") + startingTime.minutes.toString().padStart(2, "0") + startingTime.seconds.toString().padStart(2, "0");
    const endTime = endingTime.hours.toString().padStart(2, "0") + endingTime.minutes.toString().padStart(2, "0") + endingTime.seconds.toString().padStart(2, "0");

    await updateDoc(alertCollectionRef, {
      Detection: detections,
      EventTypes: events,
      StartTime: startTime,
      EndTime: endTime,
      WeeklyRepetitiveDays: datesConfig.WeeklyRepetitiveDays,
      // MonthlyRepetitiveDays: datesConfig.MonthlyRepetitiveDays,
      // NonRepetitiveDates: datesConfig.NonRepetitiveDates,
      ExclusiveDates: datesConfig.ExclusiveDates,
      PurposeList: purposeList,
      RegisteredOrganizationList: registeredOrganizationList,
	  isEntry:isEntry,
	  isEvent:isEvent
    });
  }

  useEffect(() => {
    if (selectedClientID && selectedLocationID && devices.length) {
      let zoneDataArray = devices.filter((ele) => ele.ClientID === selectedClientID && ele.Location_ID === selectedLocationID);
      let uniqueZoneDataArray = [...new Map(zoneDataArray.map((item) => [item["Zone"], item])).values()];
      const zoneOptionsList = uniqueZoneDataArray.map((ele) => ({ value: ele.ZoneID, label: ele.Zone }));
      setZoneOptions(zoneOptionsList);

      // setting default zone
      setSelectedZoneOption({ value: uniqueZoneDataArray?.[0]?.ZoneID, label: uniqueZoneDataArray?.[0]?.Zone });

      //fetching detections
      fetchDetections()
        .then(() => {
          fetchEventTypes().catch((err) => console.log("Err Fetching Directions(event types", err));
        })
        .catch((err) => console.log("Err Fetching Detections", err));
    }
  }, [selectedClientID, selectedLocationID, devices]);

  useEffect(() => {
    const setZoneData = async () => {
      setIsLoading(true);
      const alertProfileString = `${selectedClientID}-${selectedLocationID}-SAIA-${selectedZoneOption.value}`;
      setAlertProfile(alertProfileString);

      const alertCollectionRef = doc(db, "SecurityAgentInitiationAlert", alertProfileString);
      const docSnap = await getDoc(alertCollectionRef);
      if (docSnap.exists()) {
        setSelectedEventTypesOptions([...docSnap.data().EventTypes.map((vehicle) => ({ label: vehicle, value: vehicle }))]);
        setSelectedDetectionOptions([...docSnap.data().Detection.map((vehicle) => ({ label: vehicle, value: vehicle }))]);
        const [startHr, startMin, startSec] = [parseInt(docSnap.data().StartTime.slice(0, 2)), parseInt(docSnap.data().StartTime.slice(2, 4)), parseInt(docSnap.data().StartTime.slice(4, 6))];
        const [endHr, endMin, endSec] = [parseInt(docSnap.data().EndTime.slice(0, 2)), parseInt(docSnap.data().EndTime.slice(2, 4)), parseInt(docSnap.data().EndTime.slice(4, 6))];
        setStartingTime({
          hours: startHr,
          minutes: startMin,
          seconds: startSec,
        });
        setEndingTime({ hours: endHr, minutes: endMin, seconds: endSec });
        setPurposeList(docSnap.data().PurposeList);
        setRegisteredOrganizationList(docSnap.data().RegisteredOrganizationList);
        setDatesConfig({
          // NonRepetitiveDates: docSnap.data().NonRepetitiveDates,
          ExclusiveDates: docSnap.data().ExclusiveDates,
          WeeklyRepetitiveDays: docSnap.data().WeeklyRepetitiveDays,
          // MonthlyRepetitiveDays: docSnap.data().MonthlyRepetitiveDays,
        });
		setIsEntry(docSnap?.data()?.isEntry)
		setIsEvent(docSnap?.data()?.isEvent)
      } else {
        await setDoc(alertCollectionRef, {
          AlertProfile: alertProfileString,
          EventTypes: [],
          Detection: [],
          StartTime: "000000",
          EndTime: "000000",
          PurposeList: [],
          RegisteredOrganizationList: [],
          ExclusiveDates: [],
          WeeklyRepetitiveDays: [],
		  isEntry:true,
	  	  isEvent:true
          // MonthlyRepetitiveDays: [],
        });
      }
      setIsLoading(false);
    };

    if (selectedZoneOption.value) setZoneData().catch((err) => console.log("set zone error", err));
  }, [selectedZoneOption, selectedLocationID]);

  const handleSave = async () => {
    updateDB()
      .then(() => {
        toast.success("Saved !");
      })
      .catch((err) => {
        toast.error("Could not save , ran into problems");
        console.log("err", err);
      });
  };

  // if (isLoading) return <Loading />;

  if (!isLoading && !zoneOptions.length)
    return (
      <div className="flex flex-row justify-around">
        <div className="alert alert-error w-1/3">
          <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <span>No Movement Alert Configuration is not available for this Location</span>
        </div>
      </div>
    );

  return (
    <div className="mx-6">
      {/* Title */}
      <h1 className="text-sky-900 text-[32px] font-semibold">Security Agent Initiation Alert</h1>
      {isLoading === false ? (
        <div className="pr-10">
          <div className="flex gap-10">
            <div class="inline-flex items-center">
              <label class="flex items-center cursor-pointer relative" for="check-1">
                <input type="checkbox" checked={isEvent} onClick={e=>setIsEvent(!isEvent)} class="peer h-5 w-5 cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-slate-300 checked:bg-slate-800 checked:border-slate-800" id="check-1" />
                <span class="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor" stroke="currentColor" stroke-width="1">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                  </svg>
                </span>
              </label>
              <label class="cursor-pointer ml-2 text-slate-600 text-sm" for="check-1">
                Event
              </label>
            </div>
			<div class="inline-flex items-center">
              <label class="flex items-center cursor-pointer relative" for="check-2">
                <input type="checkbox" checked={isEntry} onClick={e=>setIsEntry(!isEntry)} class="peer h-5 w-5 cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-slate-300 checked:bg-slate-800 checked:border-slate-800" id="check-2" />
                <span class="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor" stroke="currentColor" stroke-width="1">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                  </svg>
                </span>
              </label>
              <label class="cursor-pointer ml-2 text-slate-600 text-sm" for="check-2">
                Entry
              </label>
            </div>
          </div>
          <div className="flex flex-row items-end justify-between my-8 w-[100rem] relative z-[2]">
            <div className="flex flex-col gap-4 lg:flex-row lg:items-center lg:gap-8">
              {/* Zone div */}
              <div className="flex flex-row justify-between items-end w-[33.4rem]">
                <h1 className="text-black text-2xl font-normal self-center">Zone</h1>
                <Select className="text-black" options={zoneOptions} value={selectedZoneOption} placeholder={"Select Zone"} onChange={(selectedOption) => setSelectedZoneOption(selectedOption)} styles={selectStyles} />
              </div>
            </div>
            <button className="fixed right-32 z-20 btn btn-accent hover:btn-success font-bold text-base" onClick={handleSave}>
              Save
            </button>
          </div>
          <DetectionEventTypes
            eventTypesOptions={eventTypesOptions}
            selectedEventTypesOptions={selectedEventTypesOptions}
            setSelectedEventTypesOptions={setSelectedEventTypesOptions}
            detectionOptions={detectionOptions}
            selectedDetectionOptions={selectedDetectionOptions}
            setSelectedDetectionOptions={setSelectedDetectionOptions}
          />
          {/* Space for start and endtime components */}
          <TimePickerMain startingTime={startingTime} setStartingTime={setStartingTime} endingTime={endingTime} setEndingTime={setEndingTime} />
          {/* Repeat component */}
          <h2 className="my-3 text-black font-semibold lg:text-[32px] md:text-lg lg:text-left">Repetitive</h2>
          <div className="my-9 lg:grid grid-cols-1 items-start justify-around lg:mx-20">
            <div className="lg:flex lg:flex-col md:flex md:flex-row lg:gap-7 items-center md:gap-[7rem]">
              <p className="font-normal text-2xl text-slate-700 inline-block w-[68px]">Weekly</p>
              <WeekDaysPicker selectedDays={datesConfig.WeeklyRepetitiveDays} setDatesConfig={setDatesConfig} />
            </div>
            {/* <div className="lg:flex lg:flex-col md:flex md:flex-row lg:gap-7 items-center md:gap-[7rem]">
							<p className="font-normal text-2xl text-slate-700 inline-block w-[68px]">Monthly</p>
							<MonthlyDaysPicker selectedDatesInMonth={datesConfig.MonthlyRepetitiveDays} setDatesConfig={setDatesConfig} />
						</div> */}
          </div>

          <NonRepetitiveDates exclusiveDates={datesConfig.ExclusiveDates} setDatesConfig={setDatesConfig} />
          <PurposeList purposeList={purposeList} setPurposeList={setPurposeList} />
          <RegisteredOrganizationMain registeredOrganizationList={registeredOrganizationList} setRegisteredOrganizationList={setRegisteredOrganizationList} />
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default SecurityAgentInitiationAlertMain;
