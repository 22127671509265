import React from "react";

const ResolutionsModal = ({ resolutionsData, setModal }) => {
	return (
		<>
			<input type="checkbox" id="modal" className="modal-toggle" />
			<div className="modal">
				<div className="modal-box relative rounded-none bg-white w-[30rem]">
					<label
						onClick={() => setModal(false)}
						htmlFor="modal"
						className="btn btn-sm border-none btn-circle absolute right-2 top-2 text-white bg-[#375075] hover:bg-[#A6A6A6]"
					>
						✕
					</label>
					<h1 className="text-xl font-bold text-center text-black p-2">Resolutions</h1>
					<div className="container mx-auto p-4">
						<table className="min-w-full border border-gray-300 bg-slate-100 text-black">
							<thead className="bg-blue-100">
								<tr>
									<th className="px-2 py-1 text-center">S.No.</th>
									<th className="w-1/2 px-2 py-1 text-center">Validated By</th>
									<th className="w-1/2 px-2 py-1 text-center">Remarks</th>
								</tr>
							</thead>
							<tbody>
								{resolutionsData.map((resolution, i) => (
									<tr key={`${i}_${i + 1}`}>
										<td className="px-2 py-1 text-center">{i + 1}</td>
										<td className="px-2 py-1 text-center">{resolution["Validated By"]}</td>
										<td className="px-2 py-1 text-center">{resolution["Remarks"]}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
};

export default ResolutionsModal;
