import { useState } from "react";
import AlMonkLogo from "../../../components/AlMonkLogo";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthProvider/AuthProvider";
import useMasterAdmin from "../../../hooks/useMasterAdmin";
import { DataContext } from "../../../context/DataProvider/DataProvider";
import Loading from "../../../components/Loading/Loading";
import { collection, doc, onSnapshot, query, runTransaction, setDoc, updateDoc, where } from "firebase/firestore";
import { db, storage } from "../../../firebase/firebase.config";
import { useEffect } from "react";
import CreateEntryForm from "./CreateEntryForm";
import TaggedEventsDisplay from "./TaggedEventsDisplay";
import { dateTimeInReadableFormat, eventTodaysDate, getTimeStampFromDateParam } from "../../../Utility/FixTodaysDate";
import CreateEntryImage from "./CreateEntryImage";
import AddEntryImageModal from "../../../components/AddEntryImageModal";
import ImageCaptureModal from "../../../components/ImageCaptureModal";
import ImagePreviewModal from "../../../components/ImagePreviewModal";
import toast from "react-hot-toast";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

async function fetchOrgsAndPurpose(
	clientID,
	locationID,
	zoneID,
	setAvailableRegisteredOrgs,
	setAvailablePurposes,
	setSelectedRegisteredOrg,
	setSelectedPurpose
) {
	const unsub = onSnapshot(
		doc(db, "SecurityAgentInitiationAlert", `${clientID}-${locationID}-SAIA-${zoneID}`),
		(doc) => {
			const orgs = doc.data().RegisteredOrganizationList;
			const purposes = doc.data().PurposeList;
			setAvailableRegisteredOrgs(orgs);
			setSelectedRegisteredOrg(orgs?.at(0));
			setAvailablePurposes(purposes);
			setSelectedPurpose(purposes?.at(0));
		},
		(error) => console.log("Error fetching purpose and orgs", error.message)
	);
	return () => unsub();
}

async function fetchLocationNames(clientID, clientLocationIDs, setAvailableLocations, setSelectedLocation) {
	const unsub = onSnapshot(
		query(collection(db, `clients-data-collection/${clientID}/Location`), where("Location_ID", "in", clientLocationIDs)),
		(snapShot) => {
			let list = [];
			snapShot.docs.forEach((doc) => {
				list.push({ id: doc.id, name: doc.data().Name });
			});
			setAvailableLocations(list);
			setSelectedLocation(list?.at(0));
		},
		(error) => {
			console.log(error);
		}
	);

	return () => {
		unsub();
	};
}

async function fetchZones(clientID, selectedLocation, setAvailableZones, setSelectedZone) {
	const unsub = onSnapshot(
		query(collection(db, `clients-data-collection/${clientID}/Location/${selectedLocation.id}/Zones`)),
		(snapShot) => {
			let list = [];
			snapShot.docs.forEach((doc) => {
				list.push({ id: doc.id, name: doc.data().ZoneName });
			});
			setAvailableZones(list);
			setSelectedZone(list?.at(0));
		},
		(error) => {
			console.log(error);
		}
	);

	return () => {
		unsub();
	};
}

async function fetchDetections(clientID, selectedLocation, setAvailableDetections, setSelectedDetection) {
	const unsub = onSnapshot(
		query(collection(db, `clients-data-collection/${clientID}/Location/${selectedLocation.id}/Detections`)),
		(snapShot) => {
			let list = [];
			snapShot.docs.forEach((doc) => {
				list = doc.data()?.Vehicles;
			});
			setAvailableDetections(list);
			setSelectedDetection(list?.at(0));
		},
		(error) => {
			console.log("error fetching detections", error.message);
		}
	);

	return () => {
		unsub();
	};
}

const CreateEntryMain = () => {
	const { user, authLoading } = useContext(AuthContext);
	const [isMasterAdmin, masterAdminLoading] = useMasterAdmin(user?.email);
	const { userClientAndLocation } = useContext(DataContext);
	const clientID = userClientAndLocation?.userClientID;

	const [photoUploadModal, setPhotoUploadModal] = useState(false);
	const [files, setFiles] = useState([]);

	const [selectedEventsID, setSelectedEventsID] = useState([]);

	const [selectedEventType, setSelectedEventType] = useState("Entry");

	const [availableLocations, setAvailableLocations] = useState([]);
	const [selectedLocation, setSelectedLocation] = useState({ id: "", name: "" });

	const [availableZones, setAvailableZones] = useState([]);
	const [selectedZone, setSelectedZone] = useState({ id: "", name: "" });

	const [availableDetections, setAvailableDetections] = useState([]);
	const [selectedDetection, setSelectedDetection] = useState("");

	const [availablePurposes, setAvailablePurposes] = useState([]);
	const [selectedPurpose, setSelectedPurpose] = useState("");

	const [availableRegisteredOrgs, setAvailableRegisteredOrgs] = useState([]);
	const [selectedRegisteredOrg, setSelectedRegisteredOrg] = useState("");

	const [taggedEventVideoURLs, setTaggedEventVideoURLs] = useState([]);

	const [dateDisplay, setDateDisplay] = useState("");
	const [timestamp, setTimestamp] = useState("");
	useEffect(() => {
		const date = new Date();
		setDateDisplay(dateTimeInReadableFormat(date));
		setTimestamp(getTimeStampFromDateParam(date));
	}, []);

	const [imageURLForPreview, setImageURLForPreview] = useState(null);
	const [imagePreviewModal, setImagePreviewModal] = useState(false);
	const [cameraModal, setCameraModal] = useState(false);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			await fetchLocationNames(clientID, userClientAndLocation?.userLocation, setAvailableLocations, setSelectedLocation);
		};
		if (clientID) fetchData();
	}, [clientID]);

	useEffect(() => {
		const fetchData = async () => {
			await fetchZones(clientID, selectedLocation, setAvailableZones, setSelectedZone);
			await fetchDetections(clientID, selectedLocation, setAvailableDetections, setSelectedDetection);
		};
		if (clientID && selectedLocation?.id) fetchData();
	}, [clientID, selectedLocation.id]);

	useEffect(() => {
		const fetchData = async () => {
			await fetchOrgsAndPurpose(
				clientID,
				selectedLocation.id,
				selectedZone.id,
				setAvailableRegisteredOrgs,
				setAvailablePurposes,
				setSelectedRegisteredOrg,
				setSelectedPurpose
			);
			setLoading(false);
		};
		if (clientID && selectedLocation.id && selectedZone.id) fetchData();
	}, [clientID, selectedLocation.id, selectedZone.id]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const form = e.target;
		const time = form.time.value;
		const number = form.number.value;
		const remarks = form.remarks.value;

		if (!files.length) {
			toast.error("Upload images to proceed");
			return;
		}
		const toastId = toast.loading("Saving...");
		try {
			const downloadURLs = await handleUpload(files);
			toast.loading("Uploading Images...", {
				id: toastId,
			});
			await addEntryData(
				selectedEventType,
				selectedLocation.name,
				selectedLocation.id,
				selectedZone.name,
				selectedZone.id,
				timestamp,
				selectedDetection,
				number,
				selectedPurpose,
				selectedRegisteredOrg,
				remarks,
				downloadURLs
			);
			await updateDetectionEvents(selectedLocation.id);
			setSelectedEventsID([]);
			setFiles([]);
			toast.success("Saved", {
				id: toastId,
			});
			form.reset();
		} catch (err) {
			toast.error("Error Saving", {
				id: toastId,
			});
			console.log("Error Saving", err);
		}
	};

	async function addEntryData(eventType, location, locID, zone, zoneID, time, detection, number, purpose, sender, remarks, downloadURLs) {
		try {
			const docID = `${clientID}-${locID}-${timestamp}`;
			await setDoc(doc(db, `${clientID}-entries-${timestamp.split("-").at(0)}`, docID), {
				docId: docID,
				Action: eventType,
				ClientID: clientID,
				Detection: detection,
				Entryby: user?.displayName,
				EventId: selectedEventsID,
				Location: location ? location : null,
				LocationID: locID ? locID : null,
				"Video-URL": taggedEventVideoURLs,
				"Photo-URL": downloadURLs.length ? downloadURLs : [],
				Purpose: purpose ? purpose : null,
				Sender: sender,
				Remarks: remarks ? remarks : null,
				Status: "Pending",
				Timestamp: time,
				VehicleNo: number ? number : null,
				Zone: zone ? zone : null,
				ZoneID: zoneID ? zoneID : null,
			});
		} catch (err) {
			console.error("error", err);
		}
	}

	async function updateDetectionEvents() {
		const dateStamp = timestamp.split("-").at(0);
		const collectionName = `${clientID}-priority-${dateStamp}`;
		const entryID = `${clientID}-${selectedLocation.id}-${timestamp}`;

		const updatePromises = selectedEventsID.map((eventID) => {
			const eventDocRef = doc(db, `${clientID}-detection-events-${dateStamp}`, eventID);
			return runTransaction(db, async (transaction) => {
				const eventDoc = await transaction.get(eventDocRef);
				if (eventDoc.exists()) {
					const entryDocRef = doc(db, collectionName, eventID);
					transaction.set(entryDocRef, { ...eventDoc.data(), id: eventID, Status: "Pending", Priority: "Critical", EntryID: entryID });
				}
			})
				.then(() => console.log(`${eventID} updated`))
				.catch((err) => {
					console.log(`Could not update ${eventID}`, err);
					throw new Error(`Could not update ${eventID}`);
				});
		});

		try {
			await Promise.all(updatePromises);
		} catch (err) {
			console.log("Could not update eventids", err);
		}
	}

	const handleUpload = async (files) => {
		// Set the folder name
		const folderName = `entry-${timestamp.split("-").at(0)}`;

		// array to store download URLs
		const downloadURLs = [];

		// array of upload promises
		const uploadPromises = files.map((file) => {
			// Create a storage reference with the specified folder and file name
			const storageRef = ref(storage, `${folderName}/${file?.name}`);

			// Create and return a promise for each upload task
			return new Promise((resolve, reject) => {
				const uploadTask = uploadBytesResumable(storageRef, file);

				uploadTask.on(
					"state_changed",
					(snapshot) => {
						const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						// console.log(`Upload ${file?.name} is ${progress}% done`);
					},
					(error) => {
						reject(`Error uploading file ${file?.name}: ${error.message}`);
					},
					() => {
						getDownloadURL(uploadTask.snapshot.ref)
							.then((downloadURL) => {
								downloadURLs.push(downloadURL); // Store the download URL
								resolve(`Upload ${file?.name} complete. Download URL: ${downloadURL}`);
							})
							.catch((downloadError) => {
								reject(`Error getting download URL for file ${file?.name}: ${downloadError.message}`);
							});
					}
				);
			});
		});

		try {
			await Promise.all(uploadPromises);
			return downloadURLs; // Return the array of download URLs
		} catch (error) {
			console.log("Error during uploads:", error);
			throw error; // Rethrow the error to handle it in the calling code
		}
	};

	if (loading) return <Loading />;

	return (
		<div className="bg-[#E9F1FE] h-full overflow-x-hidden">
			{/* header div */}
			<div className="flex bg-inherit items-center justify-between py-2 sticky top-0 z-[3]">
				<h1 className="font-semibold lg:ml-6 md:ml-12 lg:text-[32px] md:text-2xl text-start  text-black">Create Entry</h1>
				<div className="flex items-center justify-end">
					<input type="text" placeholder="Search" className="input input-bordered rounded mr-3 bg-white placeholder:text-zinc-800" />
					<div className="ml-5">
						<AlMonkLogo />
					</div>
				</div>
			</div>

			{/* Body Div */}
			<div className="mt-4 ml-6 mr-4">
				{/* Submit Button Div */}
				<div className="flex flex-row justify-end">
					<button className="btn btn-success text-white px-16 pb-3 pt-2 font-bold text-xl" type="submit" form="create-entry-form">
						Submit
					</button>
				</div>

				<div className="my-3 grid grid-cols-3 gap-4">
					<CreateEntryForm
						selectedEventType={selectedEventType}
						setSelectedEventType={setSelectedEventType}
						availableDetections={availableDetections}
						availableLocations={availableLocations}
						availableZones={availableZones}
						availablePurposes={availablePurposes}
						availableRegisteredOrgs={availableRegisteredOrgs}
						selectedLocation={selectedLocation}
						setSelectedLocation={setSelectedLocation}
						selectedZone={selectedZone}
						setSelectedZone={setSelectedZone}
						selectedDetection={selectedDetection}
						setSelectedDetection={setSelectedDetection}
						selectedPurpose={selectedPurpose}
						setSelectedPurpose={setSelectedPurpose}
						selectedRegisteredOrg={selectedRegisteredOrg}
						setSelectedRegisteredOrg={setSelectedRegisteredOrg}
						dateDisplay={dateDisplay}
						handleSubmit={handleSubmit}
					/>
					<TaggedEventsDisplay
						queryData={{
							Direction: selectedEventType,
							Date: eventTodaysDate(),
							clientID: clientID,
							locationID: selectedLocation.id,
							zoneID: selectedZone.id,
						}}
						selectedEventsID={selectedEventsID}
						setSelectedEventsID={setSelectedEventsID}
						taggedEventVideoURLs={taggedEventVideoURLs}
						setTaggedEventVideoURLs={setTaggedEventVideoURLs}
					/>
					<CreateEntryImage files={files} setFiles={setFiles} setPhotoUploadModal={setPhotoUploadModal} />
				</div>

				{photoUploadModal ? (
					<AddEntryImageModal
						setPhotoUploadModal={setPhotoUploadModal}
						setCameraModal={setCameraModal}
						setImageURLForPreview={setImageURLForPreview}
						setImagePreviewModal={setImagePreviewModal}
					/>
				) : (
					<></>
				)}
				{cameraModal ? <ImageCaptureModal setCameraModal={setCameraModal} setFiles={setFiles} /> : <></>}
				{imagePreviewModal ? (
					<ImagePreviewModal imageURLForPreview={imageURLForPreview} setImagePreviewModal={setImagePreviewModal} files={files} setFiles={setFiles} />
				) : (
					<></>
				)}
			</div>
		</div>
	);
};

export default CreateEntryMain;
